import React                   from 'react';
import styled                  from '@emotion/styled';
import PropTypes               from 'prop-types';
import { Heading as CHeading } from '@chakra-ui/react';

const Container = styled.div`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  hyphens: auto;
  -webkit-hyphens: none;
  margin-bottom: 2rem;

  h1,h2,h3,h4,h5,h6 {
    font-weight: unset;
    margin-bottom: 0;
  }

  img {
    margin-bottom: 0;
  }

  &:after {
    background-color: ${props => props.color ? props.color : props.theme.brand_color};
    display: block;
    content: "";
    height: 1px;
    width: 120px;
    margin: 15px auto 20px;
    position: relative;
  }
`;

const Subtitle = styled.div`
  font-weight: 300;
  font-size: 1.4rem;
  margin-top: 15px;
  text-transform: none;
`;

function Heading(props) {
  const { tag, children, subtitle } = props;
  return (
    <>
      <Container id={props.id}>
        <CHeading as={tag}>
          {children}
        </CHeading>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Container>
    </>
  )
}

Heading.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  subtitle: PropTypes.string,
  tag: PropTypes.string,
  isImage: PropTypes.bool,
};

Heading.defaultProps = {
  tag: 'h1',
  isImage: false,
};

export default Heading;
